<template>
  <div>
    <b-button
      type="submit"
      variant="primary progress from-button"
      @click="modalProgress"
      >Check Progress</b-button
    >
    <b-modal
      v-model="modalShow"
      centered
      no-close-on-backdrop
      id="check_progress"
      ref="modal"
      size="lg"
      :title="`Order #${orderNumber} Shipping Progress`"
    >
      <b-overlay :show="loading">
        <div class="list-unstyled">
          <div
            class="media-box"
            :class="{ hide: pseudoShow }"
            v-for="(data, index) in checkProgressData"
            :key="index"
          >
            <div class="media-h">
              <h5 class="mt-0 mb-6">Shipment {{ index + 1 }}</h5>
              <b-button
                class="pseudo-slider"
                @click="pseudoSliderShow(data[0].shipment_id)"
              >
                Logistics information
                <b-icon icon="chevron-right"></b-icon>
              </b-button>
            </div>
            <b-media
              v-for="(subsetData, subsetIndex) in data"
              :key="subsetIndex"
            >
              <template v-slot:aside>
                <b-img :src="subsetData.image" width="64"></b-img>
              </template>
              <div class="media-text">
                <p class="mb-2">
                  <b>{{ subsetData.name }}</b>
                </p>
                <p class="mb-0">
                  <b>{{ subsetData.price_incl_tax }}</b>
                </p>
              </div>

              <p class="subscript">Quantity: {{ subsetData.shipped_qty }}</p>
            </b-media>
          </div>

          <div class="pseudo-slider-box" :class="{ 'fade-in': pseudoShow }">
            <b-button
              class="pseudo-slider-return"
              :class="{ hide: !pseudoShow }"
              @click="pseudoSliderShow('return')"
              v-if="getOrderExpressIf"
            >
              <b-icon icon="chevron-left"></b-icon>Back
            </b-button>
            <div class="process" :class="{ hide: !pseudoShow }">
              <ul>
                <li v-for="(data, index) in pseudoSliderData" :key="index">
                  <p class="description">{{ data.context }}</p>
                  <span class="time">{{ data.time }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button size="sm" variant="secondary" @click="close()">{{
          $t('page.close')
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
export default {
  name: 'CheckProgress',
  data: function() {
    return {
      modalShow: false,
      pseudoShow: false,
      loading: true,
      timer: '',
      getOrderExpressIf: true,
      checkProgressData: [],
      pseudoSliderData: []
    };
  },
  components: {},
  props: {
    orderNumber: String,
    orderId: String
  },
  methods: {
    modalProgress: function() {
      this.modalShow = true;
      this.pseudoShow = false;
      apiBus.order
        .getShipmentInfo({
          order_id: this.orderId
        })
        .then(data => {
          console.log(data);
          if (data.data.code == '200') {
            if (data.data.data.length > 0) {
              this.checkProgressData = data.data.data;
              this.loading = false;
            } else {
              this.getOrderExpressIf = false;
              this.checkProgressData = data.data.data;
              this.pseudoSliderShow(null);
            }
          }
        });
    },
    pseudoSliderShow: function(shipment_id) {
      this.pseudoSliderData = [];
      this.pseudoShow ? (this.pseudoShow = false) : (this.pseudoShow = true);
      console.log(shipment_id);

      if (shipment_id != 'return') {
        var t;
        clearTimeout(t);
        t = setTimeout(() => {
          this.loading = true;
          if (this.pseudoSliderData.length == 0) {
            var data = {
              order_id: this.orderId
            };
            if (shipment_id != null) {
              data.shipment_id = shipment_id;
            }
            apiBus.order.getOrderExpress(data).then(data => {
              this.pseudoSliderData = data.data.data;
              this.loading = false;
              console.log(data);
            });
          } else {
            this.loading = false;
          }
        }, 800);
      }
    },
    init: function() {}
  },
  computed: {
    // category_id() {
    //   return this.$route.query.category_id;
    // }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
b {
  font-weight: bold;
}
.list-unstyled {
  position: relative;
}
.modal-body .position-relative {
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
}
.media {
  margin-bottom: 10px;
  background-color: #faf9fc;
  padding: 8px;
  position: relative;
}
.media-box {
  position: relative;
  margin-bottom: 15px;
}
.media-box img {
  max-width: 64px;
  max-height: 64px;
}
.pseudo-slider,
.pseudo-slider-return {
  color: #00a0e8 !important;
}
.pseudo-slider,
.pseudo-slider:hover {
  padding: 0 5px;
  background-color: transparent !important;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.pseudo-slider-return,
.pseudo-slider-return:hover {
  padding: 0 5px;
  background-color: transparent !important;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}
b,
strong {
  font-weight: bold;
}
.subscript {
  position: absolute;
  right: 8px;
  bottom: 8px;
  margin: 0;
}
.media-text {
  margin-top: 10px;
}
.media-h {
  margin-bottom: 8px;
}
</style>
